import React from 'react';
// import sections
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';


const Home = () => (
  <React.Fragment>
    <HeroFull className="illustration-section-01" />
    <FeaturesTiles />
    <FeaturesSplit invertMobile topDivider imageFill />
    <Cta split />
  </React.Fragment>
)

export default Home;